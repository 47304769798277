<template>
  <hub-modal :visible="true" class="create-board-modal">
    <template #title>
      <span v-if="board.readonly">{{ board.title }}</span>
      <span v-else>{{ board.createNew ? 'Create' : 'Update' }} board</span>
    </template>
    <template #footer>
      <div class="footer-buttons">
        <div>
          <p-button v-if="!board.readonly && !board.createNew" color="secondary" :test-id="'delete-button'" @click="deleteBoard">Delete</p-button>
        </div>
        <div>
          <p-button variant="text" :disabled="isRequestPending" @click="$emit('close')">Cancel</p-button>
          <p-button
            v-if="!board.readonly"
            style="justify-self: start"
            type="button"
            color="primary"
            :test-id="'save-button'"
            :disabled="(v$.$anyDirty && v$.$invalid) || isRequestPending"
            @click.prevent.stop="submit()"
            >Save
          </p-button>
        </div>
      </div>
    </template>
    <div class="create-board-modal-body">
      <form :class="{ dirty: v$.$anyDirty }" autocomplete="off" class="create-board-form" @submit.stop.prevent="submit">
        <div class="form-row">
          <p-text-field
            v-model="title"
            label="Title"
            autocomplete="off"
            :multiline="false"
            :test-id="'board-title'"
            :disabled="isRequestPending || board.readonly"
          />
          <ul v-if="v$.title">
            <li v-if="!v$.title.required" class="error">This field is required</li>
          </ul>
        </div>
        <div class="form-row">
          <p-button
            v-if="!owners.includes('everyone')"
            variant="text"
            class="claim-button"
            color="primary"
            :disabled="board.readonly"
            @click.stop.prevent="addAll('owners')"
          >
            Assign to everyone
          </p-button>
          <p-assignees v-model:value="owners" :test-id="'assignees_owners'" :disabled="board.readonly" label="Owners (can read and edit board)" />
        </div>
        <div class="form-row">
          <p-button
            v-if="!readers.includes('everyone')"
            variant="text"
            class="claim-button"
            color="primary"
            :disabled="board.readonly"
            @click.stop.prevent="addAll('readers')"
          >
            Assign to everyone
          </p-button>
          <p-assignees v-model:value="readers" :test-id="'assignees_readers'" :disabled="board.readonly" label="Readers (can read board)" />
        </div>
      </form>
    </div>
  </hub-modal>
</template>

<script>
import { mapState } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';

import Assignees from '@/components/Assignees';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'hub-modal': Modal,
    'p-assignees': Assignees
  },
  props: {
    board: {
      type: Object,
      default: null
    }
  },
  emits: ['created', 'close'],
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      title: this.board.title || '',
      owners: this.board.owners || [],
      readers: this.board.readers || []
    };
  },
  computed: {
    ...mapState({
      isRequestPending: s => s.boards.isCreateRequestPending
    })
  },
  validations() {
    return {
      title: { required }
    };
  },

  methods: {
    async submit() {
      try {
        this.v$.$touch();

        if (this.v$.$invalid) {
          return;
        }
        const payload = { title: this.title, readers: this.readers, owners: this.owners };
        if (this.board.createNew) {
          await this.$store.dispatch(`boards/create`, payload);
        } else {
          await this.$store.dispatch(`boards/update`, { id: this.board.id, ...payload });
        }
        this.$emit('created');

        this.$trackEvent(`Board ${this.board.createNew ? 'creared' : 'updated'}`);

        this.v$.$reset();
      } catch (e) {
        this.$toast.error({
          title: 'Failed to create a board',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    addAll(target) {
      this[target] = ['everyone'];
    },
    async deleteBoard() {
      const { id, title } = this.board;

      const confirmResult = await this.$confirm({
        title: 'Delete board?',
        message: `Are you sure you want to delete board '${title}'?\n\nThis action can't be undone.`,
        confirm: 'Delete'
      });

      if (!confirmResult) {
        return;
      }

      const lock = this.$lock();
      try {
        await this.$store.dispatch('boards/delete', id);

        this.$toast.success({
          title: 'Board deleted',
          message: `Board '${title}' (${id}) was successfully deleted.`
        });
        this.$emit('created');
      } catch (e) {
        this.$toast.error({
          title: 'Failed to delete a board',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock.release();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-board-modal-body {
  padding: 1.5rem 2rem 0.5rem 2rem;

  .create-board-form {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: minmax(0, 1fr);

    min-height: 100%;
    .form-row {
      position: relative;
      .claim-button {
        position: absolute;
        right: 0;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        font-size: 0.7rem;
        cursor: pointer;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
      margin: 0;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>

<style>
.create-board-modal .modal {
  width: 500px;
  height: 500px;
  grid-template-rows: max-content minmax(0, 1fr) max-content;
  min-width: unset;
}

@media screen and (max-device-width: 1023px), screen and (max-width: 1023px) {
  .create-board-modal .modal {
    width: 100%;
    height: 100%;
  }
}
</style>
