<template>
  <section class="boards">
    <header style="display: flex; justify-content: space-between; align-items: center">
      <h2 v-if="splittedCollection.owned.length">My boards:</h2>
      <!-- <div> -->
      <hub-button color="primary" :disabled="isGetCollectionRequestPending" @click="createModalVisible = { createNew: true }"
        >Create new board</hub-button
      >
      <!-- </div> -->
    </header>
    <hub-create-board v-if="createModalVisible" :board="createModalVisible" @close="closeModal" @created="onBoardCreated" />
    <div v-if="isGetCollectionRequestPending" style="place-self: center">
      <hub-icon name="loading" spin size="lg"></hub-icon>
    </div>
    <ul v-else class="board-list">
      <li v-for="item of splittedCollection.owned" :key="item.id" class="board-list-item">
        <router-link :to="`/boards/${item.id}`" class="board-list-item-link" @click.prevent="$e => $e.preventDefault()">
          <div class="title-wrapper">
            <label>{{ item.title }}</label>
            <hub-button variant="icon" type="button" class="edit-button" :test-id="'edit-button'" @click.stop="$e => editBoard($e, item)">
              <hub-icon name="pencil" />
            </hub-button>
          </div>
          <span class="created-by">{{ item.createdBy }}</span>
        </router-link>
      </li>
    </ul>

    <header v-if="splittedCollection.readed.length" style="display: flex; justify-content: space-between; align-items: center">
      <h2>Boards shared with me:</h2>
    </header>
    <div v-if="isGetCollectionRequestPending" style="place-self: center">
      <hub-icon name="loading" spin size="lg"></hub-icon>
    </div>
    <ul v-else class="board-list">
      <li v-for="item of splittedCollection.readed" :key="item.id" class="board-list-item">
        <router-link :to="`/boards/${item.id}`" class="board-list-item-link">
          <div class="title-wrapper">
            <label>{{ item.title }}</label>
            <hub-button variant="icon" type="button" class="edit-button" @click.stop="$e => editBoard($e, { ...item, readonly: true })">
              <hub-icon name="information-outline" />
            </hub-button>
          </div>
          <span class="created-by">{{ item.createdBy }}</span>
        </router-link>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Icon from './../common/Icon';
import Button from './../common/Button';
import CreateBoardModal from './CreateBoardModal';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-create-board': CreateBoardModal
  },
  data() {
    return {
      createModalVisible: false
    };
  },
  computed: {
    ...mapState({
      isGetCollectionRequestPending: s => s.boards.isGetCollectionRequestPending,
      collection: s => s.boards.collection,
      total: s => s.boards.total,
      email: s => s.identity.email
    }),
    splittedCollection() {
      if (!this.collection.length) {
        return { owned: [], readed: [] };
      }
      const owned = this.collection.filter(
        board => board.createdBy === this.email || board.owners.includes(this.email) || board.owners.includes('everyone')
      );

      const readed = this.collection.filter(
        board => board.createdBy !== this.email && !board.owners.includes(this.email) && !board.owners.includes('everyone')
      );
      return { owned, readed };
    }
  },
  created() {
    this.$trackEvent(`Board list opened`);
    this.$store.dispatch('boards/getCollection');
  },
  methods: {
    closeModal() {
      this.createModalVisible = null;
    },
    onBoardCreated() {
      this.createModalVisible = null;
    },
    editBoard(e, item) {
      e.preventDefault();
      e.stopPropagation();
      this.createModalVisible = item;
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.boards {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;

  h2 {
    background-color: transparent;
    margin: 1rem 0;
  }
}
.board-list {
  display: grid;
  // justify-content: flex-start;
  grid-auto-rows: 100px;
  // grid-auto-columns: 20%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  overflow-y: auto;

  .board-list-item {
    border: 1px solid transparent;
    border-radius: 3px;
    background: var(--theme-surface);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    .board-list-item-link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .created-by {
      display: none;
      font-size: 0.75rem;
      font-style: italic;
    }
    .edit-button {
      display: none;
    }

    &:hover {
      .edit-button {
        display: block;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid var(--theme-highlight);
        }
      }
      .created-by {
        display: block;
      }
    }
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 0.65rem 0.75rem;
    }
    .title-wrapper {
      display: flex;
      justify-content: space-between;
    }

    label {
      color: var(--theme-on-surface);
      font-size: 1.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
    }

    &:hover {
      border: 1px solid var(--theme-highlight);
      cursor: pointer;
      label {
        color: var(--theme-on-surface);
        cursor: pointer;
      }
    }

    &.create {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-color: transparent;

      label {
        transform: translateY(-5px);
      }
      i {
        font-style: italic;
        font-size: 0.9rem;
        text-transform: uppercase;
      }

      &:hover {
        border-color: transparent;
        cursor: pointer;

        label {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
