<template>
  <hub-boards />
</template>

<script>
import Boards from './../components/boards/Boards.vue';

export default {
  components: {
    'hub-boards': Boards
  },
  mounted() {}
};
</script>
